@font-face {
	font-family: "NeurialGrotesk";
	src: local("NeurialGrotesk"), url(./fonts/NeurialGrotesk-Regular.otf) format("opentype");
}
@font-face {
	font-family: "NeurialGrotesk";
	src: local("NeurialGrotesk"), url(./fonts/NeurialGrotesk-Medium.otf) format("opentype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "NeurialGrotesk";
	src: local("NeurialGrotesk"), url(./fonts/NeurialGrotesk-Bold.otf) format("opentype");
	font-weight: bold;
	font-style: normal;
}
body {
	margin: 0;
	font-family: NeurialGrotesk, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
		"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #e4e5e6;
}

code {
	font-family: NeurialGrotesk, source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
	margin: 0;
	box-sizing: border-box;
}

.chart-loading {
	width: 100%;
	height: 100%;
	position: absolute;
	opacity: 0.6;
	background: white;
	text-align: center;
	top: 30%;
}
